<template>
  <b-card-code
    title="Courses"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    
    <table class="table">
  <thead class="thead-light">
    <tr>
      <th scope="col">Sr #</th>
      <th scope="col">Month Name</th>
      <th scope="col">Videos Count</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(attachment, index) in monthlyAttachments" :key="index">
      <th scope="row">{{index+1}}</th>
      <td v-if="attachment.month">{{attachment.month.name}}</td>
      <td>{{attachment.total_videos}}</td>
      <td>
        <div class="text-nowrap">
            <b-button @click="deleteCourseAttachment(attachment.month_id)" variant="outline-danger" size="sm"><b-icon icon="archive-fill"></b-icon></b-button>
            <b-button v-if="attachment.total_videos > 0"  class="ml-1" variant="outline-danger" :to="'/course-video/'+course_id+'/'+attachment.month_id+''" size="sm"><b-icon icon="youtube"></b-icon></b-button>
        </div>
      </td>
    </tr>
    
  </tbody>
</table>
    <!-- pagination -->
      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import CourseService from '@/services/course.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
      course_id: this.$route.params.courseId,
      monthlyAttachments: [],
      attachmentData: {
        month_id: 0,
        course_id: 0
      }
    }
  },
   watch:{
        '$route'(to) {
            this.course_id = to.params.courseId;
        } 
    },
  methods: {
    getMaterial() {
        this.showOverlay = true;
        CourseService.adminMonthlyVideo(
            this.course_id
        ).then(response => {
            this.monthlyAttachments = response.data.data.monthlyAttachments;
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
    
    deleteCourseAttachment(month_id) {
      this.attachmentData.course_id = this.course_id;
      this.attachmentData.month_id = month_id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          CourseService.deleteCourseAttachment(this.attachmentData).then(response => {
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'Videos has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  
                  const attachmentIndex = this.monthlyAttachments.findIndex(attachment => attachment.month_id === month_id);
                  this.monthlyAttachments.splice(attachmentIndex, 1);   
                  this.showOverlay = false;
                    
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
          
        }
      })
    }
  },
  created() {
    this.getMaterial();
  }
}
</script>
